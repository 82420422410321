const ROUTE_HOME = '/';
const ROUTE_UNAUTHORIZED = '/sign-in';
const ROUTE_MODERATION = '/profile';
const ROUTE_GAS_B2C = '/gas-b2c';
const ROUTE_GAS_B2B = '/gas-b2b';
const ROUTE_GAS_B2C_DASHBOARD = '/gas-b2c/dashboard';
const ROUTE_GAS_B2B_DASHBOARD = '/gas-b2b/dashboard';
const ROUTE_ENERGIYA_B2B = '/energiya/dashboard';
const ROUTE_DASHBOARD = '/dashboard';
const ROUTE_DOCUMENTS = '/gas-b2b/documents';
const ROUTE_DOCUMENTS_CONTRACTS = '/gas-b2b/documents/contracts';
const ROUTE_ENERGY_DOCUMENTS = '/energiya/documents';
const ROUTE_ENERGY_DOCUMENTS_CONTRACTS = '/energiya/documents/contracts';
const ROUTE_TECHNICAL_WORK = '/technical-work';
const ROUTES_UNAUTHORIZAED_ALLOWED = [
  '/',
  '/b2b',
  '/b2b/sign-up',
  '/sign-in',
  '/sign-up',
  '/forgot-password',
  '/b2b-bot/auth',
  '/b2b-bot/documents',
  '/technical-work',
];
const ROUTES_BOT = ['/b2b-bot'];
const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

export {
  ROUTE_HOME,
  ROUTE_UNAUTHORIZED,
  ROUTE_MODERATION,
  ROUTES_UNAUTHORIZAED_ALLOWED,
  ROUTE_GAS_B2B_DASHBOARD,
  ROUTE_ENERGIYA_B2B,
  ROUTE_GAS_B2C,
  ROUTE_GAS_B2B,
  ROUTE_GAS_B2C_DASHBOARD,
  EMAIL_REGEXP,
  ROUTES_BOT,
  ROUTE_DASHBOARD,
  ROUTE_DOCUMENTS,
  ROUTE_DOCUMENTS_CONTRACTS,
  ROUTE_ENERGY_DOCUMENTS,
  ROUTE_ENERGY_DOCUMENTS_CONTRACTS,
  ROUTE_TECHNICAL_WORK,
};

export const evenPayment = 'ТВІЙ ГАЗ РІВНОМІРНИЙ ПЛАТІЖ';
